module.exports = [{
      plugin: require('../../../../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"delayLoad":false,"delayLoadUntilActivity":false,"delayLoadUntilActivityAdditionalDelay":0,"devKey":"Z37CoUSPQU4YianaAzVd9UMurXutpQ1j","manualLoad":false,"prodKey":"WNhuzlWVXqIDFo8nrFECQBdSJfvRQQnW","trackPage":false,"trackPageImmediately":false,"trackPageOnlyIfReady":false,"trackPageOnRouteUpdate":false,"trackPageWithTitle":false},
    },{
      plugin: require('../../../../node_modules/gatsby-theme-i18n/gatsby-browser.js'),
      options: {"plugins":[],"configPath":"/opt/build/repo/apps/fe/website/src/i18n/config.json","defaultLang":"en"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-decap-cms/gatsby-browser.js'),
      options: {"plugins":[],"manualInit":true,"modulePath":"./static/admin-ttzpubxivj/netlify.js","publicPath":"admin-ttzpubxivj"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#FFF1F1","crossOrigin":"use-credentials","display":"standalone","icon":"src/images/favicon.svg","name":"Noah Website","short_name":"NoahWeb","start_url":"/","theme_color":"#FFF1F1","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"a2bb54125e1593309de8f5c1ddca9105"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"enableWebVitalsTracking":true,"id":"GTM-T4MFVDL","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
