import type { Dispatch, ReactElement, SetStateAction } from 'react';
import { createContext, useMemo, useState } from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';
import type { MarkdownRemarkFrontmatter, Maybe } from '../../../generated/types';
import type { Arkonaut } from '../../pages/team';

export type PpWebsiteContext = {
  buySellResultInfo: MarkdownRemarkFrontmatter | null | undefined;
  isScrolled: boolean;
  languageDialogInfo: Record<'button' | 'label', Maybe<string> | undefined>;
  selectedArkonaut: Arkonaut | null | undefined;
  setBuySellResultInfo: Dispatch<SetStateAction<MarkdownRemarkFrontmatter | null | undefined>>;
  setLanguageDialogInfo: Dispatch<
    SetStateAction<Record<'button' | 'label', Maybe<string> | undefined>>
  >;
  setSelectedArkonaut: Dispatch<SetStateAction<Arkonaut | null | undefined>>;
};

export const WebsiteContext = createContext({} as PpWebsiteContext);

export function WebsiteContextProvider({ children }: PpWC): ReactElement {
  const [selectedArkonaut, setSelectedArkonaut] = useState<Arkonaut | null | undefined>(null);
  const [buySellResultInfo, setBuySellResultInfo] = useState<
    MarkdownRemarkFrontmatter | null | undefined
  >(null);
  const [languageDialogInfo, setLanguageDialogInfo] = useState<
    Record<'button' | 'label', Maybe<string> | undefined>
  >({ button: '', label: '' });
  const [isScrolled, setIsScrolled] = useState(false);

  useScrollPosition(
    ({ currPos }) => {
      const isShow = currPos.y < -70;
      if (isShow !== isScrolled) {
        setIsScrolled(isShow);
      }
    },
    [isScrolled],
  );

  const value = useMemo(
    () => ({
      buySellResultInfo,
      isScrolled,
      languageDialogInfo,
      selectedArkonaut,
      setBuySellResultInfo,
      setLanguageDialogInfo,
      setSelectedArkonaut,
    }),
    [
      selectedArkonaut,
      setSelectedArkonaut,
      isScrolled,
      buySellResultInfo,
      setBuySellResultInfo,
      languageDialogInfo,
      setLanguageDialogInfo,
    ],
  );

  return <WebsiteContext.Provider value={value}>{children}</WebsiteContext.Provider>;
}
