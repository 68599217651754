import WebFont from 'webfontloader';
import { RootProviders } from './src/RootProviders.tsx';

export const wrapRootElement = RootProviders;

export function onInitialClientRender() {
  WebFont.load({
    custom: {
      families: ['GT America Expanded', 'GT America Extended', 'GT America Standard'],
    },
  });
}
