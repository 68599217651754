import type { ReactElement } from 'react';
import { Fragment } from 'react';
import { CssBaseline, GlobalStyles } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Dialogs, DialogsProvider } from '@noah-labs/fe-shared-ui-components';
import type { WrapRootElementBrowserArgs } from 'gatsby';
import { Helmet } from 'react-helmet';
import { WebsiteContextProvider } from './components/context';
import { LinkBehaviorGatsby } from './components/navigation/LinkBehaviorGatsby';
import { globalStyles, theme } from './theme';
import './components/iubenda/default.css';
import './components/iubenda/custom.css';

export function RootProviders({ element }: WrapRootElementBrowserArgs): ReactElement {
  return (
    <Fragment>
      <Helmet>
        <meta
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
          name="viewport"
        />
      </Helmet>
      <ThemeProvider theme={theme(LinkBehaviorGatsby)}>
        {/* Keeping CssBaseline & GlobalStyles right after ThemeProvider and before ErrorBoundary, ensures correct styles are applied to the Error scenes */}
        <CssBaseline />
        <GlobalStyles styles={globalStyles} />
        <WebsiteContextProvider>
          <DialogsProvider>
            {element}
            <Dialogs />
          </DialogsProvider>
        </WebsiteContextProvider>
      </ThemeProvider>
    </Fragment>
  );
}
