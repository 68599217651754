export const websiteColors = {
  black: '#383838',
  darkGrey: '#717171',
  gradients: {
    lavenderTransparentGradient: 'linear-gradient(180deg, #9B6CFF -71.61%, #FFFFFF 216.74%)',
    softBlueGradient: 'linear-gradient(180deg, #F2F4FF 0%, #FFF 100%)',
    tangerineTransparentGradient:
      'linear-gradient(90deg, rgba(255,255,255,0) 50%, rgba(255,107,0,1) 100%)',
  },
  grey: '#D8D8D8',
  lighterGrey: '#F9F9F9',
  lightGrey: '#EAEAEA',
  mediumGrey: '#C4C4C4',
  mediumPurple: '#9B6CFF',
  plainBlack: '#000000',
  semiTransparentWhite: 'rgba(255,255,255,0.7)',
  tangerine: '#FF6B00',
  white: '#ffffff',
};
