import type { ElementType } from 'react';
import type { LinkProps, ThemeOptions } from '@mui/material';
import { coreThemeConstants } from '@noah-labs/fe-shared-ui-components';
import { palette } from './palette';
import { typography } from './typography';

const smallButtonPadding = coreThemeConstants.spacing(0.75, 2.5);
const buttonPadding = coreThemeConstants.spacing(1.25, 3);
const { primary } = palette?.websiteBrand || {};
const { black, darkGrey, white } = palette?.websiteBrand?.greyscale || {};

export function components(
  Link: ElementType | undefined,
  borderRadius: ThemeOptions['borderRadius'],
): ThemeOptions['components'] {
  return {
    MuiButton: {
      styleOverrides: {
        contained: {
          ':hover': {
            backgroundColor: 'transparent',
          },
          borderStyle: 'solid',
          borderWidth: '2px',
        },
        containedPrimary: {
          ':hover': {
            borderColor: primary,
            color: primary,
          },
          backgroundColor: primary,
          borderColor: primary,
          color: white,
        },
        containedSecondary: {
          ':hover': {
            borderColor: black,
            color: black,
          },
          backgroundColor: black,
          borderColor: black,
          color: white,
        },
        containedSizeLarge: {
          padding: buttonPadding,
        },
        containedSizeMedium: {
          padding: buttonPadding,
        },
        containedSizeSmall: {
          padding: smallButtonPadding,
        },
        outlined: {
          ':hover': {
            borderWidth: '2px',
            color: white,
          },
          borderStyle: 'solid',
          borderWidth: '2px',
        },
        outlinedPrimary: {
          ':hover': {
            backgroundColor: primary,
            borderColor: primary,
          },
          backgroundColor: 'transparent',
          borderColor: primary,
          color: primary,
        },
        outlinedSecondary: {
          ':hover': {
            backgroundColor: darkGrey,
            borderColor: darkGrey,
          },
          backgroundColor: 'transparent',
          borderColor: darkGrey,
          color: darkGrey,
        },
        outlinedSizeLarge: {
          padding: buttonPadding,
        },
        outlinedSizeMedium: {
          padding: buttonPadding,
        },
        outlinedSizeSmall: {
          padding: smallButtonPadding,
        },
        root: {
          ':active': {
            boxShadow: '3px 5px 15px rgba(0, 0, 0, 0.1)',
          },
          borderRadius: '30px',
          textTransform: 'none',
        },
        sizeLarge: {
          ...typography.buttonL,
        },
        sizeMedium: {
          ...typography.button,
        },
        sizeSmall: {
          ...typography.button,
        },
        text: {
          ':hover': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
          },
          padding: 0,
        },
        textPrimary: {
          color: primary,
        },
        textSecondary: {
          color: darkGrey,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: Link, // See: https://next.material-ui.com/guides/routing/#global-theme-link
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiChip: {
      defaultProps: {
        color: 'primary',
        variant: 'outlined',
      },
      styleOverrides: {
        colorSecondary: {
          borderColor: darkGrey,
          color: darkGrey,
        },
        icon: {
          fontSize: typography.button?.fontSize,
          marginLeft: coreThemeConstants.spacing(1.5),
        },
        root: {
          borderWidth: '2px',
          height: 'fit-content',
          padding: coreThemeConstants.spacing(0.5, 0),
          ...typography.button,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: coreThemeConstants.spacing(4),
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          backgroundColor: white,
          border: `solid 1px ${palette.websiteBrand?.greyscale.lightGrey || '#ccc'}`,
          borderRadius: '5px',
          padding: coreThemeConstants.spacing(1.5, 24, 1.5, 1.125),
        },
      },
    },
    MuiLink: {
      defaultProps: {
        component: Link, // See: https://next.material-ui.com/guides/routing/#global-theme-link
      } as LinkProps,
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          ...typography.button,
          ':active, :focus': {
            borderColor: primary,
            borderStyle: 'solid',
            borderWidth: '2px',
          },
          borderColor: darkGrey,
          borderRadius: borderRadius.xl,
          borderStyle: 'solid',
          borderWidth: '2px',
          fontSize: '1rem', // must be at least 16px to prevent iOS auto-zoom on input field
          padding: coreThemeConstants.spacing(1.5, 3),
        },
        notchedOutline: {
          display: 'none',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            borderWidth: 0,
          },
          borderWidth: 0,
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        grouped: {
          ':not(:first-of-type),:not(:last-of-type)': {
            borderLeft: 'none',
            borderRadius: borderRadius.md,
            margin: 0,
          },
          padding: coreThemeConstants.spacing(0.5),
        },
        root: {
          padding: coreThemeConstants.spacing(0.25, 0.5),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: white,
          borderColor: primary,
          borderStyle: 'solid',
          borderWidth: '1px',
          color: black,
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          navigationItem: 'span',
        },
      },
    },
  };
}
