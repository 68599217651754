/* eslint-disable react/no-multi-comp */
import { forwardRef } from 'react';
import { Dialog, Slide } from '@mui/material';
import type { DialogProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { TransitionProps } from '@mui/material/transitions';

const Transition = forwardRef<
  unknown,
  TransitionProps & {
    children: React.ReactElement;
  }
>((props, ref) => <Slide ref={ref} direction="left" {...props} />);

Transition.displayName = Transition.name;

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-container': {
    justifyContent: 'flex-end',
  },
  '& .MuiDialog-paper': {
    borderRadius: 0,
    margin: 0,
    maxHeight: '100%',
    maxWidth: '100%',
    minHeight: '100%',
    padding: theme.spacing(2),
    width: theme.breakpoints.values.sm,
  },
}));

export function SideDialog({ children, ...rest }: DialogProps): React.ReactElement {
  return (
    <StyledDialog TransitionComponent={Transition} {...rest}>
      {children}
    </StyledDialog>
  );
}
