import type { PaletteOptions } from '@mui/material/styles';
import { websiteColors } from './websiteColors';

export const palette: PaletteOptions = {
  primary: {
    contrastText: websiteColors.white,
    main: websiteColors.tangerine,
  },
  text: {
    dark: websiteColors.darkGrey,
    light: websiteColors.mediumGrey,
    primary: websiteColors.black,
    secondary: websiteColors.tangerine,
    white: websiteColors.white,
  },
  websiteBrand: {
    gradients: {
      footerGradient: websiteColors.gradients.softBlueGradient,
      primaryLinear: websiteColors.gradients.tangerineTransparentGradient,
    },
    greyscale: {
      black: websiteColors.black, // 383838
      darkGrey: websiteColors.darkGrey, // 717171
      grey: websiteColors.grey, // D8D8D8
      lighterGrey: websiteColors.lighterGrey, // 181818
      lightGrey: websiteColors.lightGrey, // F9F9F9
      mediumGrey: websiteColors.mediumGrey, // C4C4C4
      plainBlack: websiteColors.plainBlack, // 00000
      semiTransparentWhite: websiteColors.semiTransparentWhite, // rgba(255,255,255,0.7)
      white: websiteColors.white, // ffffff
    },
    primary: websiteColors.tangerine,
    rewards: {
      primary: websiteColors.mediumPurple,
    },
  },
};
